/**
 * Account page
 */
.account-page {
  background: #fcfcfc;
  padding-top: 50px;
  padding-bottom: 100px;

  @media(max-width: 767px) {
    padding: 16px;
  }

  &.wallet {
    @media(max-width: 767px) {
      padding: 0;
    }
  }

  .panel {
    @apply rounded;
    background-color: #fff;
    border: 1px solid #c7cfd4;
  }
  .panel-bottom {
    height: 78px;
    padding: 16px;
    background-color: #f7f7f7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    .button {
      min-width: 160px;
    }
    @media(max-width: 767px) {
      height: auto;
      .button {
        width: 100%;
      }
    }
  }
  .form {
    width: 555px;
    margin: 70px auto;
    @media(max-width: 767px) {
      width: 100%;
      margin: 24px auto 40px;
      padding: 0 16px;
    }
    h3 {
      font-weight: 600;
      margin-bottom: 50px;

      @media(max-width: 767px) {
        margin: 10px 0 30px;
      }
    }
  }
}

.account-form {
  .tips {
    margin-top: -10px;
    margin-bottom: 0;
    font-size: 12px;
    color: #6d7782;
  }
  .preview {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: #EBEFF4;
    overflow: hidden;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
}

.account-menus {
  li {
    list-style: none;
    padding: 0.875rem 0;
    border-top: 1px solid #EBEFF4;

    &:first-child {
      border-top: none;
    }

    i.ix {
      margin-right: 1rem;
      font-weight: 500;
      font-size: 20px;
    }
  }
}

/**
 * Account page end
 */


/**
 * Account wallet page
 */
.accout-wallet-header {
  padding: 2rem 2.5rem;
  border-bottom: 1px solid #EBEFF4;
  @media(max-width: 767px) {
    padding: 1rem 1.25rem;
  }
}
.accout-wallet-history {
  padding: 2rem 2.5rem;
  @media(max-width: 767px) {
    padding: 1rem 1.25rem;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .history-list {
    .note-long {
      max-width: 220px;
      min-width: 180px;
    }
  }

  .price-popover-bar {
    position: relative;
    cursor: pointer;

    .ix {
      position: absolute;
      right: -16px;
    }
  }
}
/**
 * Account wallet page end
 */

/**
 * Account touup
 */
.accout-topup-side-header {
  border-bottom: 1px solid #ebeff4;
  padding: 20px 32px;
}
@media(max-width: 767px) {
  .accout-topup-side-header {
    padding: 20px 16px;
  }
}
.accout-topup-side-header .icon {
  margin-right: 24px;
  width: 40px;
}
.accout-topup-side-content {
  padding: 16px 32px;
}
@media(max-width: 767px) {
  .accout-topup-side-content {
    padding: 20px 16px;
  }
}
.accout-topup-side-content .topup-item {
  padding: 8px 0;
}

.account-page-width {
  max-width: 640px;
  margin: 0 auto;
  // overflow: hidden;
}

/**
 * Account order page start
 */

.order-list-height {
  height: 128px;
  @media(max-width: 600px) {
    height: 144px;
  }
}

/**
 * Account order page end
 */
