/* magic number on which all sizing is based*/
$control-label-text-size: 16px;

$input-control: transparent;
$input-control-active: theme("colors.fish-primary.default");
$input-control-border: #c7cfd4;
$input-control-disabled: #EBEFF4;
$input-control-disabled-checked: #EBEFF4;

.gs-form-group-input {
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  input[type="radio"][disabled],
  input[type="checkbox"][disabled] {
    cursor: not-allowed;
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    position: relative;
    display: block;
    padding-left: 1.875 * $control-label-text-size;
    cursor: pointer;
    vertical-align: middle;
    font-size: $control-label-text-size;
    margin-bottom: 0.5 * $control-label-text-size;

    &:hover {
      &:before {
        border: 1px solid $input-control-active;
      }
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1.25 * $control-label-text-size / 2;
      display: inline-block;
      width: 1.25 * $control-label-text-size;
      height: 1.25 * $control-label-text-size;
      content: "";
      -webkit-transition: border 0.2s ease;
      -o-transition: border 0.2s ease;
      transition: border 0.2s ease;
      border: 1px solid $input-control-border;
      background: $input-control;
    }

    &:after {
      position: absolute;
      content: "";
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  input[type="radio"][disabled] + label,
  input[type="checkbox"][disabled] + label {
    cursor: not-allowed;
    color: $input-control-disabled;

    &:hover,
    &:before,
    &:after {
      cursor: not-allowed;
    }

    &:before {
      border-color: $input-control-disabled;
    }
  }

  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    &:before {
      -webkit-animation-name: none;
      animation-name: none;
    }
    &:after {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }

  input[type="radio"] + label {
    &:before {
      border-radius: 50%;
    }

    &:after {
      margin-top: -0.5 * $control-label-text-size / 2;
      top: 50%;
      left: 0.375 * $control-label-text-size;
      width: 0.5 * $control-label-text-size;
      height: 0.5 * $control-label-text-size;
      border-radius: 50%;
      background: $input-control-active;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }
  }

  input[type="radio"]:checked + label {
    &:before {
      border: 1px solid $input-control-active;
    }
  }
  input[type="radio"]:checked[disabled] + label {
    &:before {
      border: 1px solid $input-control-disabled-checked;
    }

    &:after {
      background: $input-control-disabled-checked;
    }
  }

  /* Checkbox specific sytyling*/

  input[type="checkbox"] + label {
    &:before {
      @apply rounded-sm;
    }

    &:after {
      margin-top: - $control-label-text-size / 2;
      top: 50%;
      left: 0.4375 * $control-label-text-size;
      width: 0.375 * $control-label-text-size;
      height: 0.75 * $control-label-text-size;
      -webkit-transform: scale(0) rotate(45deg);
      -ms-transform: scale(0) rotate(45deg);
      transform: scale(0) rotate(45deg);
      border-width: 0.125 * $control-label-text-size;
      border-style: solid;
      border-color: #fff;
      border-top: 0;
      border-left: 0;
    }
  }
  input[type="checkbox"]:checked + label {
    &:before {
      border: $input-control-active;
      background: $input-control-active;
    }

    &:after {
      -webkit-transform: scale(1) rotate(45deg);
      -ms-transform: scale(1) rotate(45deg);
      transform: scale(1) rotate(45deg);
    }
  }

  input[type="checkbox"]:checked[disabled] + label {
    &:before {
      border: $input-control-disabled-checked;
      background: $input-control-disabled-checked;
    }
  }
  .is-invalid ~ .invalid-feedback {
    display: block;
  }
}
.input-checkbox {
  input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
  }
  input[type="checkbox"] + span {
    &:before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      content: "\2713";
      color: white;
      transform: scale(0.8);
      font-weight: 900;
      line-height: 1;
      font-size: 16px;
      text-align: center;
      border: 2px solid $input-control-border;
      border-radius: 3px;
      background-color: white;
      cursor: pointer;
    }
  }
  input[type="checkbox"]:checked + span {
    &:before {
      border-color: $input-control-active;
      background: $input-control-active;
    }
  }
}

.checkbox-label-group {
  input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
  }
  input[type="checkbox"] + label {
    &:before {
      position: absolute;
      z-index: 0;
      top: 3px;
      left: 0;
      content: "\2713";
      color: white;
      transform: scale(0.8);
      font-weight: 900;
      line-height: 1;
      font-size: 18px;
      height: 20px;
      width: 20px;
      text-align: center;
      border: 1px solid $input-control-border;
      border-radius: 3px;
      background-color: white;
      cursor: pointer;
    }
  }
  input[type="checkbox"]:checked + label {
    &:before {
      border-color: $input-control-active;
      background: $input-control-active;
    }
  }
}