/**
 * @example
 * <div class="gs-panel-card">
 *   <div class="gs-panel-card-title">Title</div>
 *   <div class="gs-panel-card-content">...</div>
 * </div>
 *
 * it's can use it single sometimes.
 * <div class="gs-panel-card-content">...</div>
 */

 .gs-panel-card {
  background: #fff;
  margin-bottom: 16px;
  position: relative;
}
.gs-panel-card .gs-panel-card-title {
  border-bottom: 1px solid #ebeff4;
  padding: 16px;
}
.gs-panel-card .gs-panel-card-title.no-border {
  border-bottom: none;
}
.gs-panel-card .gs-panel-card-content {
  padding: 16px;
}
.gs-panel-card .gs-panel-card-content.p-big {
  padding: 24px 40px;
}
@media (max-width: 767px) {
  .gs-panel-card .gs-panel-card-content.p-big {
    padding: 16px;
 }
}
.gs-panel-card .gs-panel-card-content.px-big {
  padding: 0 40px;
}
@media (max-width: 767px) {
  .gs-panel-card .gs-panel-card-content.px-big {
    padding: 0 16px;
 }
}
.gs-panel-card .gs-panel-card-content.p0 {
  padding: 0;
}
@media (max-width: 767px) {
  .gs-panel-card .gs-panel-card-content.p0 {
    padding: 0;
 }
}
.gs-panel-card.bordered {
  border: 1px solid #c7cfd4;
}
@media (max-width: 767px) {
  .gs-panel-card.bordered-xs {
    border: 1px solid #c7cfd4;
 }
}
@media (min-width: 767px) {
  .gs-panel-card.bordered-md {
    border: 1px solid #c7cfd4;
 }
}
.gs-panel-card.mb-big {
  margin-bottom: 24px;
}
.gs-panel-card.mb-0 {
  margin-bottom: 0;
}
.gs-panel-v2 ul li:last-child {
  border-bottom: none;
}