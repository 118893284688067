body {
  @apply font-helveticaNeue antialiased;
  letter-spacing: -0.1px;
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  @apply font-helveticaNeue antialiased text-fish-dark;
}

input[type="number"],
select,
textarea {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}

:focus {
  outline: none;
}

button:focus {
  outline: none;
}


b,
strong,
.b,
.strong {
  font-weight: 600;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  line-height: 1;
}
h1 {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.4px;
}
h2 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.2px;
}
h3 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.1;
}
h4 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1;
}

@media(min-width: 768px) {
  h1 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1;
  }
}

.page-main-wrapper {
  @apply px-5-tw;
}
.page-sub-sm-wrapper {
  @apply px-5-tw;
}
.page-sub-wrapper {
  @apply px-5-tw;
}
.page-sub-lg-wrapper {
  @apply px-5-tw;
}

@screen md {
  .page-main-wrapper {
    @apply pl-0 pr-5-tw;
  }
  .page-sub-sm-wrapper {
    width: 360px;
    @apply pr-0 pl-5-tw;
  }
  .page-sub-wrapper {
    width: 384px;
    @apply pr-0 pl-5-tw;
  }
  .page-sub-lg-wrapper {
    width: 424px;
    @apply pr-0 pl-5-tw;
  }
}

@screen xl {
  .page-main-wrapper {
    @apply pr-24;
  }
  .page-sub-wrapper {
    width: 464px;
    @apply pl-24;
  }
  .page-sub-lg-wrapper {
    width: 504px;
    @apply pl-24;
  }
}

.pay-logo {
  img {
    height: 22px;
  }
}

// add max width of 960px
.max-w-960 {
  max-width: 60rem;
}

/**
 * Chrome autocomplete hack
 */

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.min-hight {
  @media(min-width: 768px) {
    min-height: 650px;
  }
}

.li-hover {
  &:last-child {
    .last-border-0 {
      @apply border-b-0;
    }
  }
  &:hover {
    .textcolor-hover {
      @apply text-fish-primary;
    }
    .textcolor-fish-primary-700-hover {
      @apply text-fish-primary-700;
    }
    .text-blue-gray-700-hover {
      @apply text-blue-gray-700;
    }
    .text-blue-gray-900-hover {
      @apply text-blue-gray-900;
    }
    .buttoncolor-hover {
      @apply text-white bg-fish-primary;
    }
    .buttoncolor-fish-primary-700-hover {
      @apply text-white bg-fish-primary-700;
    }
  }
}
.hover-bg-gray-100 {
  &:hover {
    @apply bg-gray-100 #{ !important};
  }
}

.sm-last-border-0 {
  &:last-child {
    @media(max-width: 767px) {
      border-bottom: none;
    }
  }
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.menu-shadow {
  box-shadow: 1px -1px 3px 0 rgba(0, 0, 0, 0.1), 1px -1px 2px 0 rgba(0, 0, 0, 0.06);
}

.h-full-vh {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

/**
 * react date time picker
 */

.rdtOpen .rdtPicker {
  width: 100%;
}

/**
 * react modal modal-backdrop
 */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

.modal-backdrop.show {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.5);
}

.text-2xl {
  letter-spacing: -0.2px;
}

.text-4xl,
.text-5xl,
.text-6xl,
.text-7xl,
.text-8xl,
.text-9xl {
  letter-spacing: -0.4px;
}

.bg-linear-orange {
  background: linear-gradient(224.94deg, #F5C75B -1.51%, #EA3423 101.89%);
}

.h-42 {
  height: 42px;
}
.max-h-80-screen {
  max-height: 80vh;
}
.md-max-h-90-screen {
  @media(min-width: 767px) {
    max-height: 90vh;
  }
}

.underline-dot {
  @apply border-b border-dotted border-fish-primary;
  width: fit-content;
}

.bg-green-600 {
  background-color: #23C1B8 !important;
}
