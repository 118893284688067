$bg-primary: theme("colors.fish-primary.default");

.tw-badge {
  @apply inline-flex items-center justify-center text-sm rounded-full font-semibold leading-4 bg-gray-100 text-blue-gray-800;
  padding: .125rem .625rem;
}

.tw-badge.badge-lg {
  padding: .125rem .75rem; 
}

.tw-badge.badge-lg.uppercase {
  padding: .125rem .75rem; 
}

.tw-badge.badge-rounded {
  padding: .125rem .75rem; 
}

.badge {
  @apply inline-flex items-center px-3 py-px rounded-full text-white text-sm font-medium bg-transparent;
}
.badge-primary {
  background-color: $bg-primary;
}
.badge-gray {
  @apply bg-blue-gray-200;
}