.notice-wrapper {
  position: fixed;
  z-index: 9999;
  top: 48px;
  left: 16px;
  right: 16px;
  padding-bottom: 20px;
  overflow: hidden;
}

@screen md {
  .notice-wrapper {
    top: 100px;
    left: 24px;
    right: 24px;
  }
}
