.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  /*font-family: 'Georgia', serif;*/
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-editor ol,
.rich-editor-content ol {
  list-style-type: disc;
  margin-left: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;

  &.BOLD {
    font-weight: 600;
  }
  &.ITALIC {
    font-style: italic;
  }
  &.UNDERLINE {
    text-decoration: underline;
  }
}

.RichEditor-activeButton {
  color: #5890ff;
}

.editor-wrapper {
  border: 1px solid #c7cfd4;
  min-height: 6rem;
  border-radius: 0.375rem;

  .RichEditor-styleButton {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding: 0 5px;
    border-radius: 2px;
    &:hover {
      background: #EBEFF4;
    };
  }

  .RichEditor-styleButton-split {
    border-left: 1px solid #EBEFF4;
    margin: 0 8px;
  }

  .RichEditor-controls {
    margin-bottom: 0;
  }

  .DraftEditor-root {
    position: relative;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 0;
    left: 0;
    color: #6d7782;
    font-size: 14px;
  }
  .public-DraftStyleDefault-ul,
  .public-DraftStyleDefault-ol {
    margin-left: 20px;
  }
}

.rich-editor-content,
.text-note {
  ol,
  ul {
    margin-left: 20px;
  }
}

.text-note {
  font-size: 16px;
  line-height: 24px;
  h1,
  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
  }
  ul {
    padding-left: 0;
    margin-left: 20px;
  }
}
