.time-countdown {
  @apply flex items-center whitespace-no-wrap;

  small {
    font-size: 12px;
    font-weight: 500;
  }

  &.gray,
  &.gray span {
    @apply text-blue-gray-500;
  }

  &.primary,
  &.primary span,
  &.purple,
  &.purple span, {
    @apply text-indigo-700;
  }

  &.danger,
  &.danger span {
    @apply text-fish-red;
  }

  &.success,
  &.success span {
    @apply text-fish-green;
  }

  &.orange {
    color: #F2994A !important;
  }

  &.blue, &.blue span {
    @apply text-fish-primary;
  }

  &.white, &.white span {
    @apply text-white;
  }
  
}

.time-countdown-end {
  font-size: 12px;
}