/**
 * helper
 */
.help-wrapper {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  cursor: pointer;
  @apply text-center text-xxs font-inter rounded-full bg-white;
}
.help-wrapper.success {
  &.active,
  &:hover {
    @apply text-fish-green;
  }
}
.help-wrapper.danger {
  &.active,
  &:hover {
    @apply text-fish-red;
  }
}
.help-wrapper.blue {
  &.active,
  &:hover {
    @apply text-fish-primary;
  }
}
.help-wrapper.gray {
  &.active,
  &:hover {
    @apply text-blue-gray-500;
  }
}
.help-wrapper.gray.active,
.help-wrapper.gray:hover {
  &.active,
  &:hover {
    @apply text-fish-primary;
  }
}
.help-wrapper.white {
  @apply text-white;
  background: none;
}
