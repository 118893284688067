.payments-method-wrapper {
  .pay-logo {
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
    img {
      height: 32px;
    }
  }
}
.payments-method-form {
  position: relative;

  label {
    width: 100%;
    white-space: nowrap;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .col-12,
  .col-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}
