.container {
  @apply w-full mx-auto px-0;

  @screen sm {
    @apply max-w-lg;
  }

  @screen md {
    @apply max-w-2xl;
  }

  @screen lg {
    max-width: 1140px;
  }
}

.layout-v2-top-bar {
  height: 40px;
  background: linear-gradient(90deg, #ff3c61 0%, #fea365 100%);
}
.layout-v2-top-nav {
  height: 48px;
}

@screen md {
  .layout-v2-top-nav {
    height: 60px;
  }
}

.layout-notification {
  position: relative;
}
.layout-notification a {
  color: #fff;
}
.layout-notification a.button {
  border: 1px solid #fff;
  background: transparent;
}
.layout-notification .underline {
  border-bottom: 1px solid #fff;
}
.layout-notification-inner {
  padding: 14px 16px;
}
@media(max-width: 767px) {
  .layout-notification-inner {
    padding-right: 30px;
  }
}
.layout-notification-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
}
.layout-notification.danger {
  background: #ec225f;
  color: #fff;
}

/**
 * page layout
 */
.gs-page {
  @apply flex-1;

  padding-top: 82px;
  padding-bottom: 110px;
  position: relative;

  @media(max-width: 767px) {
    background: #EBEFF4;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page-pb {
  padding-bottom: 32px;
  @media(min-width: 767px) {
    padding-bottom: 150px;
  }
}

/* Layout drawer */
.drawer {
  position: fixed;
  background-color: white;
  width: 100%;
  min-height: 100%;
  top: 0;
  max-width: 300px;
  left: -105%;
  border-radius: 0;
  z-index: 999999 !important;
  transition: all 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

#navigation {
  display: none;
}

#navigation:checked ~ #overlay {
  display: block;
}
#navigation:checked ~ .drawer {
  left: 0;
}

#overlay {
  animation: fadein 0.5s;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 98;
}

.hamburger-menu {
  width: 24px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger-menu:hover span {
  @apply bg-fish-primary;
}

.hamburger-menu span {
  @apply bg-gray-800;
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburger-menu span:nth-child(1) {
  top: 0;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 7px;
}

.hamburger-menu span:nth-child(4) {
  top: 14px;
}

.hamburger-menu.open span:nth-child(1) {
  top: 7px;
  width: 0;
  left: 50%;
}

.hamburger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-menu.open span:nth-child(4) {
  top: 7px;
  width: 0;
  left: 50%;
}
.switch-w-44 {
  width: 44px;
}
.watchlist-height {
  max-height: 380px;
  overflow-y: auto;
}

.static-important {
  position: static !important;
}

.body-not-scroll {
  @apply max-h-screen overflow-hidden;
}