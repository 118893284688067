/**
 * Deposit page
 */
.deposit-head {
  padding: 20px 16px 0;
}
.deposit .money {
  font-size: 16px;
  padding: 0 32px;
  height: 48px;
  line-height: 46px;
  border: 1px solid #e8e8e8;
  background-color: #f7f7f7;
  margin-right: 8px;
}
.deposit .money-icon {
  padding: 8px 4px 0;
}
.deposit .money-icon img {
  height: 32px;
}
.deposit .button-white {
  border: 1px solid #413f97;
  color: #413f97;
}
.deposit .stripe-button-el {
  background: none;
  border-radius: 0;
  border: none;
  padding: 0;
  outline: none;
  float: right;
  margin-left: 16px;
}
.deposit .stripe-button-el span {
  padding: 0 24px;
  height: 48px;
  line-height: 48px;
  display: block;
  border-radius: 0;
  background: #413f97;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.deposit .stripe-button-el span:active {
  background: #413f97 !important;
}
@media(max-width: 767px) {
  .deposit .stripe-button-el {
    float: none;
    display: block;
    width: 100%;
    margin-left: 0;
    margin-bottom: 8px;
  }
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  @apply rounded-sm;
  background-color: white;
  height: 50px;
  line-height: 1.4em;
  padding: 14px 12px;
  border: 1px solid #EBEFF4;
  @media(max-width: 449px) {
    height: 44px;
  }
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #FE537B;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/**
 * Deposit page end
 */
