.alert-enter {
  @apply transform ease-out duration-300 transition translate-y-2 opacity-0;
  @screen sm {
    @apply translate-y-0 translate-x-2;
  }
}
.alert-enter-active {
  @apply translate-y-0 opacity-100;
  @screen sm {
    @apply translate-x-0;
  }
}
.alert-exit {
  @apply transition ease-in duration-100 opacity-100;
}
.alert-exit-active {
  @apply opacity-0;
}

.collapse-enter {
  @apply transition duration-100 ease-out transform scale-95 opacity-0;
}

.collapse-enter-active {
  @apply transform scale-100 opacity-100;
}

.collapse-exit {
  @apply transition duration-75 ease-out transform scale-100 opacity-100;
}

.collapse-exit-active {
  @apply transform scale-95 opacity-0;
}
