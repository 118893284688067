/**
 * The lastest style for "table"
 * base on bootstrap & tailwind
 *
 * @example
 *
    1. the "Table" component of Bootstrap
    <Table>
      ...
    </Table>

    <Table
      responsive
      className="[normal-table|money-table|padding-table|no-padding-table|tw-table]"
    >
      ...
    </Table>

    2. the html "table" tag
    <table
      className="table [normal-table|money-table|padding-table|no-padding-table|tw-table]"
    >
      ...
    </table>
 *
 * [] means optional
 */
th {
  @apply text-left;
}
.table {
  @apply text-blue-gray-800 mb-0 overflow-hidden;
  @media screen and (min-width: 767px) {
    @apply rounded-t-lg;
  }
  thead {
    @apply text-blue-gray-600 bg-gray-100;

    th,
    td {
      @apply text-xs border-0 py-2 font-normal border-b border-blue-gray-200;
    }
  }

  tbody {
    @apply text-blue-gray-800 font-medium;
    tr {
      &:first-child {
        td {
          @apply border-t-0;
        }
      }
    }

    td {
      @apply py-4-tw px-3-tw border-t border-blue-gray-200;
    }
    &.last-border-none {
      tr {
        &:last-child {
          @apply border-b-0;
        }
      }
    }
  }

  &.normal-table {
    thead {
      @apply bg-white;
    }
  }

  &.money-table {
    @apply rounded-none;
    thead {
      @apply text-white bg-gray-500 rounded-sm-tw;
      th,
      td {
        @apply border-b-0 whitespace-no-wrap px-2;
        &:first-child {
          @apply rounded-l-sm-tw;
        }
        &:last-child {
          @apply rounded-r-sm-tw;
        }
      }
    }
  }
  &.padding-table {
    th,
    td {
      &:first-child {
        @media screen and (min-width: 767px) {
          @apply pl-10;
        }
      }
      &:last-child {
        @media screen and (min-width: 767px) {
          @apply pr-10;
        }
      }
    }
  }
  &.no-padding-table {
    th,
    td {
      &:first-child {
        @apply pl-0;
      }
      &:last-child {
        @apply pr-0;
      }
    }
  }
  &.align-middle {
    td {
      vertical-align: middle;
    }
  }
  &.head-bg {
    tr{
      @apply border-b-0;
    }
    th{
      @apply bg-gray-50;
    }
  }
  &.no-head-bg {
    tr{
      @apply border-b border-blue-gray-200;
    }
    th{
      @apply bg-white;
    }
  }
  &.last-no-border {
    tbody {
      tr:last-child {
        @apply border-b-0;
        td {
          @apply border-b-0;
        }
      }
    }
  }
}

.tw-table {
  &.no-border {
    tbody {
      td {
        @apply border-b-0;
      }
    }
  }
  &.last-border {
    tr:last-child {
      td {
        @apply border-b border-blue-gray-200;
      }
    }
  }
  thead th {
    @apply px-6 py-3 border-b border-blue-gray-200 bg-gray-50 text-xs leading-4 font-medium text-blue-gray-500 uppercase tracking-wider;
  }
  tbody {
    @apply bg-white;
    td {
      @apply px-6 py-4 whitespace-no-wrap border-b border-blue-gray-200 text-blue-gray-500 text-sm leading-5;
      &.first-child {
        @apply font-medium text-blue-gray-900;
      }
      &.text-indigo {
        @apply text-indigo-700 font-semibold;
      }
    }
  }
}

.tw-table-v2 {
  thead {
    th {
    @apply py-3 border-none bg-gray-100 leading-4 font-medium text-blue-gray-500 uppercase tracking-wider;
    font-size: 12px;
    &:first-child {
      @apply rounded-bl-lg;
    }
    &:last-child {
      @apply rounded-br-lg;
    }
  }
}
  tbody {
    @apply bg-white;
    td {
      @apply px-3 py-4 whitespace-no-wrap border-b border-blue-gray-200 text-blue-gray-500 text-sm leading-5;
      &.first-child {
        @apply font-medium text-blue-gray-900;
      }
      &.text-indigo {
        @apply text-indigo-700 font-semibold;
      }
    }
  }
}

