.tw-modal-footer {
  button {
    @apply mt-3;
    &:first-child {
      @apply mt-0;
    }
    @screen md {
      @apply mt-0;
    }
  }
}
.tw-modal-footer.left {
  @screen md {
    @apply mt-4 ml-10 pl-4 flex;
  }

  button {
    &:first-child {
      @apply ml-0;
    }
    @screen md {
      @apply ml-3 w-auto;
    }
  }
}

.tw-modal-footer.justify {
  @screen md {
    @apply mt-6 grid grid-cols-2 gap-3;
  }
}

.tw-modal-footer.right {
  @screen md {
    @apply mt-4 flex flex-row-reverse;
  }
  button {
    &:first-child {
      @apply mr-0;
    }
    @screen md {
      @apply mr-3 w-auto;
    }
  }
}

.tw-modal-enter {
  .tw-modal-overlay {
    @apply opacity-0;
  }
  .tw-modal-card {
    @apply opacity-0 translate-y-4;
    @screen sm {
      @apply translate-y-0 scale-95;
    }
  }
}

.tw-modal-enter-active {
  .tw-modal-overlay {
    @apply opacity-100 ease-out duration-300;
  }
  .tw-modal-card {
    @apply opacity-100 translate-y-0 ease-out duration-300;
    @screen sm {
      @apply scale-100;
    }
  }
}
.tw-modal-exit {
  .tw-modal-overlay {
    @apply opacity-100;
  }
  .tw-modal-card {
    @apply opacity-100 translate-y-0;
    @screen sm {
      @apply scale-100;
    }
  }
}
.tw-modal-exit-active {
  .tw-modal-overlay {
    @apply opacity-0 ease-in duration-200;
  }
  .tw-modal-card {
    @apply opacity-0 translate-y-4 ease-in duration-200;
    @screen sm {
      @apply scale-95;
    }
  }
}