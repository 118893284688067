.show-print {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
  .show-print {
    display: block !important;
  }
  .navbar,
  .layout-bottom {
    display: none !important;
  }
  .color-info-dark {
    color: #233354;
  }
}
