/**
 * For redux form field
 */

.gs-form-group {
  margin-bottom: 16px;
}

.gs-form-group-label {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.gs-form-group-label .sub-r {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #6d7782;
  text-transform: initial;
}
.gs-form-group-label.no-capitalize {
  text-transform: none;
}
.gs-form-group-input {
  position: relative;
}
.gs-form-group-input .form-control {
  @apply rounded;
  width: 100%;
  line-height: 1em;
  padding: 14px 16px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #c7cfd4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.gs-form-group-input .form-control.is-invalid {
  border-color: theme("colors.fish-red.default");
  background-image: none;
}
.gs-form-group-input .form-control:focus {
  box-shadow: none !important;
  outline: none;
  border-color: theme("colors.blue.500");

}
.gs-form-group-input .form-control:disabled {
  @apply bg-gray-100;
}
.gs-form-group-input .form-control.box-shadow {
  @apply shadow;
}
.gs-form-group-input .form-control {
  background: #fff;
}
.gs-form-group-input textarea.form-control {
  @apply text-base leading-normal text-blue-gray-900 py-3;
  height: inherit;
}
.gs-form-group-input .form-control-sm {
  height: 40px;
  padding: 9px 16px 8px;
  font-size: 14px;
  font-weight: 500;
}
.gs-form-group-input .form-control-lg {
  height: 56px;
  padding: 0 16px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
}
.gs-form-group-input .form-control-lg::-webkit-input-placeholder,
.gs-form-group-input .form-control-lg::-webkit-input-placeholder {
  font-size: 16px;
}
.gs-form-group-input .form-control-lg:-moz-placeholder,
.gs-form-group-input .form-control-lg:-moz-placeholder {
  font-size: 16px;
}
.gs-form-group-input .form-control-lg::-moz-placeholder,
.gs-form-group-input .form-control-lg::-moz-placeholder {
  font-size: 16px;
}
.gs-form-group-input .form-control-lg:-ms-input-placeholder,
.gs-form-group-input .form-control-lg:-ms-input-placeholder {
  font-size: 16px;
}
.gs-form-group-input-mark-left {
  position: absolute;
  top: 0;
  left: 1px;
  text-align: center;
  line-height: 46px;
  height: 46px;
  min-width: 56px;
  margin-top: 1px;
  text-align: center;
  background-color: #f7fafc;
  border-right: 1px solid #c7cfd4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gs-form-group-input-mark-left span {
  font-size: 14px;
  font-weight: 400;
}
.gs-form-group-input-mark-right {
  position: absolute;
  top: 0;
  right: 1px;
  text-align: center;
  line-height: 46px;
  height: 46px;
  min-width: 56px;
  margin-top: 1px;
  text-align: center;
  background-color: #f7fafc;
  border-left: 1px solid #c7cfd4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.gs-form-group-input-mark-right span {
  font-size: 14px;
  font-weight: 400;
}
.gs-form-group-input-tips {
  font-size: 12px;
  margin-top: 16px;
}
.gs-form-group-input .invalid-feedback {
  @apply text-sm font-semibold text-fish-red leading-tight mt-2;
  display: block;
}

.gs-form-group-input.invalid .invalid-feedback {
  display: block;
}
.gs-form-group-input select.form-control {
  @apply truncate;
  padding-left: 16px;
  padding-right: 16px;
  /* Positions background arrow image */
  background-image: url("data:image/png; base64, iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 16px center;
}
.gs-form-group-input select.form-control-lg {
  padding-left: 16px;
  padding-right: 16px;
}
.gs-form-group-input-with-symbol .form-control {
  padding-left: 70px;
  padding-right: 60px;
}
@media(max-width: 767px) {
  .gs-form-group-input-with-symbol .form-control {
    padding-left: 70px;
    padding-right: 60px;
  }
}
.gs-form-group .label-radio.inline {
  display: inline-block;
  margin-right: 16px;
}
.gs-form-group .label-radio.inline:last-child {
  margin-right: 0;
}
.gs-form-group .label-radio.block {
  display: block;
}
.gs-form-group-wrapper .form-control:focus {
  border: 1px solid #3f83f8;
}
.gs-form-group-wrapper .form-control.is-invalid {
  border: 1px solid #ff5f6d;
}
.gs-form-group-wrapper .left-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.gs-form-group-wrapper .right-field {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.gs-form-group-wrapper .invalid-feedback {
  white-space: nowrap;
}
.gs-form .row {
  margin-left: -8px;
  margin-right: -8px;
}
.gs-form .row .col {
  padding-left: 8px;
  padding-right: 8px;
}

.form-control.input-datetime[readonly],
.form-control.input-date[readonly],
.form-control.input-time[readonly] {
  background: #fff;
}
.form-control.input-datetime-checkbox label,
.form-control.input-date-checkbox label,
.form-control.input-time-checkbox label {
  padding-left: 16px;
}
.form-control.input-datetime-checkbox input,
.form-control.input-date-checkbox input,
.form-control.input-time-checkbox input {
  margin-top: -20px;
  margin-left: 0;
}
.radio-group-item {
  margin-bottom: 50px;
}
.radio-group-item:last-child {
  margin-bottom: 0;
}
.radio-group-title {
  font-size: 16px;
  margin-bottom: 10px;
}
.radio-group-list {
  @apply rounded;
  border: 1px solid #c7cfd4;
}
.radio-group-list .gs-form-group-input {
  padding: 20px 0;
  border-top: 1px solid #c7cfd4;
}
.radio-group-list .gs-form-group-input:first-child {
  border-top: none;
}
.radio-group-list .gs-form-group-input label {
  margin-bottom: 0 !important;
}
.radio-group-list .gs-form-group-input label:before {
  top: 50% !important;
  margin-top: -10px;
}
.radio-group-list .gs-form-group-input label:after {
  top: 50% !important;
  margin-top: -4px;
}
.radio-group-list .gs-form-group-input .option-label {
  width: 45%;
}
.radio-group-list .gs-form-group-input .option-label label {
  font-size: 16px;
}
.radio-group-list .gs-form-group-input .sub-label {
  font-size: 16px;
  color: #6d7782;
  cursor: pointer;
}
@media(max-width: 767px) {
  .radio-group-list .gs-form-group-input .sub-label {
    text-align: right;
    font-size: 14px;
    line-height: 1.1;
  }
}
.radio-group-list.border-0 .custom-radio {
  padding-left: 0;
}

.search-form {
  @apply block text-xxs font-inter font-semibold text-blue-gray-900 mb-1;
}
.search-form .react-select {
  @apply min-h-0 leading-none #{ !important} border-none #{!important} shadow-none #{!important};
}
.search-form .react-select__control {
  @apply border-none #{ !important} shadow-none #{!important};
}
.search-form .react-select__indicator-separator {
  @apply hidden;
}
.search-form .react-select__dropdown-indicator {
  @apply hidden;
}
.search-form .react-select__clear-indicator {
  @apply hidden;
}
.search-form .react-select__value-container {
  @apply px-0 py-0 overflow-x-auto flex flex-no-wrap;
  min-height: 2.125rem;
}
.search-form .react-select__single-value {
  @apply text-base font-inter font-semibold leading-normal text-blue-gray-900;
}
.search-form .react-select__option {
  @apply text-white;
}
.search-form .react-select__placeholder {
  @apply font-inter text-blue-gray-500;
}
.search-form .react-select__multi-value {
  @apply bg-blue-50 pl-1 rounded-md flex-shrink-0;
}
.search-form .react-select__multi-value__label {
  @apply py-2 text-sm font-medium text-fish-primary;
}
.search-form .react-select__multi-value__remove {
  @apply pr-1 text-fish-primary;
}
.search-form .react-select__multi-value__remove:hover {
  @apply text-fish-primary-700 bg-blue-200;
}
.search-form .react-select__menu-list::-webkit-scrollbar {
  @apply w-1;
}
.search-form .react-select__menu-list::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-full;
}
.search-form hr {
  @apply w-px h-10 bg-gray-400 mx-6;
}
.search-form-mob .react-select {
  @apply min-h-0 leading-none #{ !important} border-none #{!important} shadow-none #{!important};
}
.search-form-mob .react-select__control {
  @apply border-none #{ !important} shadow-none #{!important};
}
.search-form-mob .react-select__indicator-separator {
  @apply hidden;
}
.search-form-mob .react-select__dropdown-indicator {
  @apply hidden;
}
.search-form-mob .react-select__clear-indicator {
  @apply hidden;
}
.search-form-mob .react-select__placeholder {
  @apply font-inter text-sm text-blue-gray-500;
}
.search-form-mob .react-select__value-container {
  @apply px-8 py-3;
}
.search-form-mob .react-select__option {
  @apply text-white;
}
.search-form-mob .react-select__menu {
  @apply z-20;
}
.search-form-mob .react-select__multi-value {
  @apply bg-blue-50 pl-1 rounded-md;
}
.search-form-mob .react-select__multi-value__label {
  @apply text-sm font-medium text-fish-primary;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.search-form-mob .react-select__multi-value__remove {
  @apply pr-1 text-fish-primary;
}
.search-form-mob .react-select__multi-value__remove svg {
  width: 20px;
  height: 20px;
}
.search-form-mob .react-select__multi-value__remove:hover {
  @apply text-fish-primary-700 bg-blue-200;
}
.form-element {
  @apply mt-2 text-sm text-fish-dark;
}
.form-element .error {
  @apply block mt-2 text-sm text-red-600;
}
.form-element .error.-mt-2 {
  @apply -mt-2 #{ !important};
}

.form-element .form-input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-element .form-input-md {
  @apply text-base pr-4;
  padding-top: 13px;
  padding-bottom: 13px;
}
.form-element .form-input-md:focus {
  @apply border-fish-primary shadow-none #{ !important};
}
.form-element .form-input-lg {
  @apply text-base font-inter pr-4 py-4;
}
.form-element .form-input-lg:focus {
  @apply border-fish-primary shadow-none #{ !important};
}
.form-element .input-form-symbol {
  @apply font-inter leading-6;
}
.form-element .form-select-lg .react-select__control {
  @apply rounded-md border-blue-gray-300;
}
.form-element .form-select-lg .react-select__control--is-focused {
  @apply border #{ !important} border-fish-primary #{!important} shadow-none #{!important};
}
.form-element .form-select-lg .react-select__value-container {
  @apply py-2 pl-3;
}
.form-element .form-select-lg .react-select__single-value {
  @apply text-base font-inter;
}
.form-element .form-select-lg .react-select__multi-value {
  @apply rounded bg-gray-200 #{ !important};
}
.form-element .form-select-lg .react-select__menu {
  @apply text-base font-inter;
}
.form-element .form-select-lg .react-select__menu-list::-webkit-scrollbar {
  -webkit-appearance: none;
}
.form-element .form-select-lg .react-select__menu-list::-webkit-scrollbar:vertical {
  width: 11px;
}
.form-element .form-select-lg .react-select__menu-list::-webkit-scrollbar:horizontal {
  height: 11px;
}
.form-element .form-select-lg .react-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.sell-quota-form .react-select__placeholder {
  @apply text-sm font-normal leading-snug text-blue-gray-500;
}
.sell-quota-form .gs-form-group-input input::-webkit-input-placeholder {
  @apply text-sm font-normal leading-snug text-blue-gray-500;
}
.sell-quota-form .gs-form-group-input input:-moz-placeholder {
  @apply text-sm font-normal leading-snug text-blue-gray-500;
}
.sell-quota-form .gs-form-group-input input:-ms-input-placeholder {
  @apply text-sm font-normal leading-snug text-blue-gray-500;
}
.sell-quota-form .form-control-sm {
  height: 50px;
  padding: 9px 16px 8px;
  font-size: 14px;
  font-weight: 500;
}
.sell-quota-form .gs-form-group-input-with-symbol .form-control {
  padding-left: 65px;
  padding-right: 60px;
}
@media(max-width: 767px) {
  .sell-quota-form .gs-form-group-input-with-symbol .form-control {
    padding-left: 65px;
    padding-right: 60px;
  }
}
.sell-quota-form .gs-form-group-input-mark-left {
  height: 44px;
  line-height: 44px;
  margin-top: 1px;
  width: 50px;
}
.sell-quota-form .gs-form-group-input-mark-left span {
  font-size: 14px;
  @apply text-blue-gray-500;
}
.sell-quota-form .gs-form-group-input-mark-right {
  line-height: 44px;
  height: 44px;
  padding: 0 4px;
  width: auto;
  margin-top: 1px;
  background-color: #f7fafc;
  border-left: 1px solid #c7cfd4;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sell-quota-form .gs-form-group-input-mark-right span {
  font-size: 14px;
  padding: 0 10px;
  @apply text-blue-gray-500;
}

.radio-wrapper .fish-primary.active {
  @apply border-fish-primary;
}

[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}

[type='radio'] {
  border-radius: 100%;
}

[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #fff;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml, %3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type='radio']:checked:hover,
[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

.radio-wrapper .fish-primary.active {
  @apply border-fish-primary;
}

.inset-no-border .react-select__control {
  border: none !important;
}


// new style of input

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }
  .input-form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
}

.input-group>:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.-ml-2px {
  margin-left: -2px;
}

.-ml-7 {
  margin-left: -28px;
}