.bid-message {
  height: 28px;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 18px;
  color: #fff;
  font-weight: 500;
}
.bid-list .product-infos {
  padding-left: 0;
  padding-right: 0;
}
.bid-list .product-infos li {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}
.bid-list .product-infos li .gs-tip {
  display: none;
}
.bid-list .product-infos li .attr {
  min-width: 180px;
}
.bid-list .product-infos li.current {
  padding: 14px 16px;
}
.bid-list .product-infos li.current .attr span, .bid-list .product-infos li.current .value {
  font-weight: 700;
}
.bid-list .product-infos li.current .attr .gs-tag-ellipse {
  padding: 0 10px;
  margin: 4px 0;
  height: 18px;
  line-height: 17px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}
.bid-list .product-infos li.current .gs-tip {
  display: block;
}
.bid-list .product-infos li.current .myself {
  line-height: 26px;
}
.bid-list .product-infos li .highest {
  font-size: 12px;
  font-weight: 600;
  color: #00cea9;
  text-transform: uppercase;
}
.bid-list .product-infos li .myself {
  color: #615fb8;
}
.bid-list .product-infos li .bid-date {
  min-width: 100px;
}
@media (max-width: 767px) {
  .bid-list .product-infos li .bid-date {
    min-width: 55px;
 }
}
.bid-list .more {
  color: #6d7782;
  padding-right: 16px;
}
.bid-list .pr0 {
  padding-right: 0 !important;
}
.bid-finished .bid-finished-header {
  background: #00aa96;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
}
.bid-finished .bid-finished-header.purple {
  background: #787883;
}
.product-infos {
  padding: 0 16px;
  margin-bottom: 0;
}
.product-infos li {
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid #ebeff4;
}
@media (min-width: 767px) {
  .product-price-list {
    margin-bottom: 50px;
 }
}
.product-price-list .product-price-list-title {
  padding: 16px;
}
.product-price-list .product-price-list-title:first-child {
  border-bottom: 16px solid #ebeff4;
}
@media (min-width: 767px) {
  .product-price-list .product-price-list-title:first-child {
    border-bottom: 1px solid #ebeff4;
 }
}
.product-price-list .product-price-list-title .price {
  font-size: 18px;
  font-weight: 700;
}
@media (min-width: 767px) {
  .product-price-list .product-price-list-title .price {
    font-size: 24px;
 }
}
.product-price-list .product-price-list-title .price small {
  font-size: 12px;
  font-weight: 400;
}
.product-price-list .product-price-list-title .price.big {
  font-size: 24px;
}
.product-price-list .product-price-list-title .button {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}
.product-price-list .product-price-list-title .side-button {
  height: 54px;
  padding-top: 7px;
}
.product-price-list .product-price-list-title-wrapper {
  position: relative;
  border-bottom: 1px solid #ebeff4;
}
@media (min-width: 767px) {
  .product-price-list .product-price-list-title-wrapper {
    border-left: 1px solid #ebeff4;
    border-right: 1px solid #ebeff4;
    background: #fcfcfc;
 }
}
.product-price-list .product-price-list-or {
  position: absolute;
  top: 79px;
  right: 16px;
  width: 160px;
}
@media (min-width: 767px) {
  .product-price-list .product-price-list-or {
    top: 72px;
 }
}
.product-price-list .product-price-list-or span {
  display: block;
  background: #ebeff4;
  font-size: 12px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
}
.product-price-list.without-child {
  margin-bottom: 0;
}
@media (min-width: 767px) {
  .product-price-list.without-child {
    margin-bottom: 50px;
 }
}
.product-price-list .gs-panel-card-content .attr {
  font-size: 14px;
  font-weight: 400;
  margin-right: 16px;
}
.product-price-list .gs-panel-card-content .value {
  font-size: 14px;
  font-weight: 500;
}
.product-price-list .gs-panel-card-content .highest {
  color: #008979;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.product-price-list .gs-panel-card-content .your {
  color: #6866cf;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.product-price-list .gs-panel-card-content .your-pre-tip {
  position: absolute;
  left: -16px;
  top: 16px;
  height: 20px;
  width: 6px;
  background: #6866cf;
  border-radius: 1px;
}
@media (max-width: 767px) {
  .product-price-list .gs-panel-card-content .your-pre-tip {
    display: none;
 }
}
.product-price-list .more {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  padding: 12px 16px;
  color: #6d7782;
}
