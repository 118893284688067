.tw-dropdown-enter {
  @apply transform opacity-0 scale-95;
}
.tw-dropdown-enter-active {
  @apply transform opacity-100 scale-100 transition ease-out duration-100;
}
.tw-dropdown-exit {
  @apply transform opacity-100 scale-100;
}
.tw-dropdown-exit-active {
  @apply transform opacity-0 scale-95 transition ease-in duration-75;
}