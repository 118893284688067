.rc-slider-handle {
  @apply border-fish-primary-700 bg-fish-primary-700 shadow w-4 h-4;
  margin-top: -6px;
}
.rc-slider-handle:hover, .rc-slider-handle:focus {
  @apply border-fish-primary-700;
}
.rc-slider-handle:active {
  @apply border-fish-primary-700;
  box-shadow: 0 0 5px #473BCB;
}
.rc-slider-track {
  @apply bg-fish-primary-700;
}
